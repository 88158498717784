import {Core} from './Core';

/**
  * instructions per second = processor speed / n
  * intel i7 about 100,000 MIPS
  */
export class Cpu {
    constructor(ncores=1, ips=1e11) {
        this.ips = ips;
        this.cores = [];
        for (let i=0; i !== ncores; ++i) {
            this.cores.push(new Core(ips));
        }
    }
}