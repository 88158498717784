import React from "react";
import selectAlg from "../images/selectalgorithm.jpeg";
import selectPtype from "../images/selectptype.jpeg";
import enterDetails from "../images/enterdetails.jpeg";
import viewProcesses from "../images/viewprocesses.jpeg";
import addRandom from "../images/addRandom.jpeg";
import changeSpeed from "../images/changeSpeed.jpeg";
import csv from "../images/csv.jpeg";
import downloadSim from "../images/downloadSim.jpeg";
import processesAdded from "../images/processesAdded.jpeg";
import processesFinished from "../images/processesFinished.jpeg";
import seeRunning from "../images/seeRunning.jpeg";



export function About() {
    return (
        <>
            <div className="container my-3" >
                <h1 className="font-weight-normal" align="center">Interactive Animated Demonstrations of Process Scheduling Algorithms</h1>
                <h5 align="left" className="font-weight-light">
                    This application was created as a third year project, with the aim of aiding and improving students
                    understanding of how different process scheduling algorithms order processes in the scheduler
                    differently. This application allows users to create processes and add them to the scheduler, and then
                    view a simulation of how these processes would be ordered to run in the CPU.
                </h5>
            </div>
            <div className="mx-3 my-3" align="center">

                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h2 className="my-4 font-weight-normal">Navigate to 'Set Up' page</h2>

                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={selectAlg} alt="select algorithm"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Select an Algorithm</h5>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p className="card-text font-weight-light">Click the dropdown 'Select an Algorithm' to build a process table to
                        your chosen algorithm.</p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={selectPtype} alt="select ptype"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Select Process Type</h5>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        <p className="card-text font-weight-light" >Click the dropdown 'Select Process Type' to choose between two different
                        process types: Constant Load Process and I/O Bound Process.</p>
                        <p className="card-text text-white font-weight-lighter" ><small>A Constant Load Process will not block
                        and will run until it is complete if that is an option. An I/O Bound Process will block and lose
                        access to the CPU as it is waiting for input/output operations to be completed.</small></p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={enterDetails} alt="enter process details"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Create Process</h5>
                        <p className="card-text font-weight-light">
                            Add details to the process such as: name, number of instructions the
                        process needs from the CPU to complete, time before a process is blocked and time it is blocked
                        for (specific to I/O Bound Process) and priority (specific to priority scheduling).
                        </p>
                        <p className="card-text text-white font-weight-lighter"><small>There are default options given, but this
                        is a chance for a user to interact with the application by changing a processes details.</small></p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={viewProcesses} alt="view processes"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Process Added to Process Table</h5>
                        <p className="card-text font-weight-light">
                            Add as many processes to the process table as you wish to view.
                        </p>
                        <p className="card-text text-white font-weight-lighter">
                            <small>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                You may also add processes on the 'Simulate Algorithm' page during the simulation, however
                                the processes added there will have less interactive features.
                            </small>
                        </p>
                    </div>
                </div>
            </div>

            <div className="mx-3 my-3" align="center">

                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h2 className="my-5 font-weight-normal">Navigate to 'Simulate Algorithm' page</h2>

                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={processesAdded} alt="processes added"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Processes Added to Simulator in Ready State</h5>
                        <p className="card-text font-weight-light">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            All processes created in the 'Set Up' page have been added to the simulator in the ready state.
                            At the top left the algorithm chosen is displayed, as well as multiple interactive buttons:
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            a rewind button which becomes active once all processes are 'Done' to replay the simulation,
                            pause and play buttons, a plus button to add another process and a trash button to empty the
                            simulator.
                        </p>
                        <p className="card-text font-weight-light">
                            On the left, you can see the running time of the simulator and a button to change the speed
                            of the simulation.
                        </p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={seeRunning} alt="see running processes"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">View Demonstration of Algorithm</h5>
                        <p className="card-textfont-weight-light">
                            As the process runs the states of the processes will change, as will the order of the processes.
                            This is dependent on the algorithm chosen.
                        </p>
                        <p className="card-text text-white font-weight-lighter"><small>Running: green running man, Ready: orange thumbs-up,
                            Blocked: red hand. </small> </p>
                            <p className="card-text text-white">
                                <small>The number below the state is the number of instructions the process has ran for.
                                </small>
                            </p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={addRandom} alt="add random process"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Add a Process During Simulation</h5>
                        <p className="card-text font-weight-light">
                            Processes enter the queue for CPU constantly in reality, therefore you can add process to the
                            simulation during the demonstration. Similar to when setting up, a Constant Load Process or
                            an I/O Bound Process can be added.
                        </p>
                        <p className="card-text text-white font-weight-lighter"><small>
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            Adding here is less interactive so it doesn't distract from the simulation. As the application
                            is set up at the moment, processes added will need 600 instructions. If an I/O Bound Process
                            is added, it will block after 200 instructions have run and block again every 200 instructions
                            until done.
                        </small></p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={changeSpeed} alt="change speed of simulation"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Set Speed of the Simulation</h5>
                        <p className="card-text font-weight-light">
                            Speed is set to 1.0 at the beginning of each demonstration, but can be slowed down if needed
                            using the clock icon.
                        </p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={processesFinished} alt="processes finished"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Simulation Finished</h5>
                        <p className="card-text font-weight-light">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            When processes are 'Done', no longer need any CPU, they move out of the simulator and are
                            shown to be done in the process table with a tick icon. The clock does not automatically stop
                            as a user may still want to add processes at this point.
                        </p>
                    </div>
                </div>

            </div>
            <div className="mx-3 my-3" align="center">

                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h2 className="my-5 font-weight-normal">Navigate to 'Download Simulation' page</h2>

                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={downloadSim} alt="download csv"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Download CSV of Context Switching</h5>
                        <p className="card-text font-weight-light">
                            Once the simulation has been viewed, the user can download a csv of the processes actions
                            within the CPU to review and help have a better understanding of the algorithm.
                        </p>
                        <p className="card-text text-white"><small>This will produce a csv of whatever processes have
                        just been simulated, not previous process tables.</small></p>
                    </div>
                </div>
                <div className="card my-3 w-75 bg-info text-white" align="center">
                    <img className="card-img-top" src={csv} alt="csv"/>
                    <div className="card-body">
                        <h5 className="card-title font-weight-light">Review CSV</h5>
                        <p className="card-text font-weight-light">
                            View the csv in your preferred way to review the algorithm.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}