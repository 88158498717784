import {Scheduler} from "./Scheduler";

export function sortPriority(pTable) {
    const tableSorted = [...pTable];
    tableSorted.sort((a, b) => {
        return a.priority - b.priority;
    });
    return tableSorted;
}

export class PriorityScheduling extends Scheduler{
    constructor() {
        super('Priority Scheduling', 5);
    }

    schedule(total, instructions) {
        this.instructions = instructions;
        this.total = total;
        this.remaining = instructions;
        let processTable = this.simulator.processTable;
        this.lastRemaining = this.remaining;
        while (this.remaining && processTable.length !== 0) {
            /**
             * update each process ready/blocked at each interval
             */
            this.updateStatus(this.total + this.instructions - this.remaining);
            processTable = sortPriority(processTable);
            /**
             * each core can have a running process
             */
            for (let icore = 0; icore !== this.simulator.cpu.cores.length; ++icore) {
                let core = this.simulator.cpu.cores[icore]

                if (this.method === 'sticky') {
                    /**
                     * if there is no process ready on the core, try to find one
                     */
                    if (!core.process || core.process.isBlocked()) {
                        core.process = this.findReadyProcess();
                    }
                } else {
                    core.process = this.findReadyProcess();
                }

                /**
                 * it is possible that no processes are able to run
                 */
                if (core.process) {
                    core.process.setRunning();
                    this.remaining = this.simulator.take(core.process, this.remaining, this.total);
                }

                /**
                 * if the process is done we remove from the process table and the core
                 * add to completed process table so we can view it
                 */
                this.simulator.completedProcessTable.push(...processTable.filter(item => item.isDone()));
                processTable = processTable.filter(item => !item.isDone());
                if (core.process && core.process.isDone()) {
                    core.process = null;
                }
            }

            this.updateRemaining(processTable);
        }
    }
}