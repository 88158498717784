import {Card, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {IOBoundProcess} from "../simulator/IOBoundProcess";


export function IOBoundProcessView({process, change, edit=false, scheduler}) {
    const [nprocess, setnprocess] = useState(process);
    const [validAdd, setValidAdd] = useState(process.instructions > 0);

    let state;
    switch (process.state) {
        case 'R':
            state = 'Ready';
            break;
        case 'N':
            state = 'Run';
            break;
        case 'W':
            state = 'Wait';
            break;
        default:
            state = 'Done';
            break;
    }

    function readOnlyView() {
        return (
            <Card >
                <Card.Header>IO Bound #{process.pid}: {process.name} {process.priority}</Card.Header>
                <Card.Body>
                    <Card.Text>{state} {process.consumedInstructions}</Card.Text>
                </Card.Body>
            </Card>
        );
    }

    function handleSubmit(event) {
        event.preventDefault();
    }

    function acceptProcess(event) {
        event.preventDefault();
        change(nprocess);
    }

    function cancelProcess(event) {
        event.preventDefault();
        change(undefined);
    }

    function changeDescription(event) {
        event.preventDefault();
        setnprocess(new IOBoundProcess(event.target.value, nprocess.pid, nprocess.instructions, nprocess.busyPeriod));
    }

    function changeInstructions(event) {
        event.preventDefault();
        let s = event.target.value;
        let instructions = 0;
        try {
            instructions = parseInt(s);
            // eslint-disable-next-line no-empty
        } catch (e) {}

        if (s === '' || s <= 0) {
            setValidAdd(false);
        } else {
            setnprocess(new IOBoundProcess(nprocess.name, nprocess.pid, instructions));
            setValidAdd(true);
        }
    }

    function changeBusyPeriod(event) {
        event.preventDefault();
        let s = event.target.value;
        let busyPeriod = 0;
        try {
            busyPeriod = parseInt(s);
            // eslint-disable-next-line no-empty
        } catch (e){}

        if (s === '' || s <= 0) {
            setValidAdd(false);
        } else {
            setnprocess(new IOBoundProcess(nprocess.name, nprocess.pid, nprocess.instructions, busyPeriod))
        }
    }


    function changePriority(event) {
        event.preventDefault();
        setnprocess(new IOBoundProcess(nprocess.name, nprocess.pid, nprocess.instructions, nprocess.busyPeriod,0, parseInt(event.target.value)));
    }

    let priorityEditor = '';
    if (scheduler === 5) {
        priorityEditor = <Form.Group className="mb-3" controlId="prioritySelect">
            <Form.Label>Select Priority</Form.Label>
            <select onChange={changePriority}>
                <option value="1" onChange={changePriority}>1</option>
                <option value="2" onChange={changePriority}>2</option>
                <option value="3" onChange={changePriority}>3</option>
            </select>
        </Form.Group>;
    }

    function editView() {
        return (
            <Form className="border border-info rounded m-2 p-2" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="processName">
                    <Form.Label>Descriptive Name</Form.Label>
                    <Form.Control type="text" value={nprocess.name?nprocess.name:''} onChange={changeDescription}
                                  placeholder="Enter name to see in process table" />
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Instructions</Form.Label>
                    <Form.Control type="number" step="100" value={validAdd ? nprocess.instructions : ''}
                                  onChange={changeInstructions} placeholder="total instructions required" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Time Running Before Blocking and Time Blocked</Form.Label>
                    <Form.Control type="number" step="100" value={validAdd ? nprocess.busyPeriod : ''}
                                  onChange={changeBusyPeriod} placeholder="num instructions before blocking" />
                </Form.Group>

                {priorityEditor}
                <div className="d-flex justify-content-center">
                    <Button className="m-2" clvariant='info' disabled={!validAdd} onClick={acceptProcess}>Add</Button>
                    <Button className="m-2" variant="secondary" onClick={cancelProcess}>Cancel</Button>
                </div>
            </Form>
        );
    }

    if (edit) {
        return editView();
    }

    return readOnlyView();
}