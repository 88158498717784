import {Scheduler} from "./Scheduler";

export function sortShortestJobFirst(pTable) {
    const tableSorted = [...pTable];
    tableSorted.sort((a, b) => {
        /**
         * must take care of processes that don't know how much they need, they shall be scheduled last
         */
        if (a.cpuNeeded === -1) {
            return 1e10;
        }
        if (b.cpuNeeded === -1) {
            return 1e10;
        }
        return a.cpuNeeded - b.cpuNeeded;
    }); return tableSorted;
}

export class ShortestJobFirst extends Scheduler {
    constructor() {
        super("Shortest Job First", 2);
    }

    schedule(total, instructions) {
        this.total = total;
        this.instructions = instructions;
        this.remaining = instructions;
        let processTable = this.simulator.processTable;
        this.lastRemaining = this.remaining;

        while (this.remaining && processTable.length !== 0) {
            /**
             * update each process ready/blocked at each interval
             */
            this.updateStatus(this.total + this.instructions - this.remaining);
            processTable = sortShortestJobFirst(processTable)
            /**
             * each core can have a running process
             */

            processTable = this.updateProcesses(processTable);
        }
    }
}
