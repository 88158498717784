import {Scheduler} from "./Scheduler";

export class RoundRobin extends Scheduler {
    constructor(quantum= 100) {
        super("Round Robin", 4);
        this.quantumDict = {};
        this.quantum = quantum;
        this.quantumConsumed = 0;
    }

    schedule(total, instructions) {
        this.instructions = instructions;
        this.total = total;
        this.remaining = this.instructions;
        let processTable = this.simulator.processTable;
        this.lastRemaining = this.remaining;
        while (this.remaining && processTable.length !== 0) {
            /**
             * update each process ready/blocked at each interval
             */
            this.updateStatus(this.total + this.instructions - this.remaining);
            /**
             * each core can have a running process
             */
            for (let icore = 0; icore !== this.simulator.cpu.cores.length; ++icore) {
                let core = this.simulator.cpu.cores[icore]

                core.process = processTable[0]; // first process in table (A)
                let moveProcess = true;
                if (!core.process.isBlocked()) {
                    core.process.setRunning();

                } else {
                    core.process.setRunning();
                    /**
                     * Round robin allows each process to take instructions at a time, cycling through repeatedly.
                     * all processes must be offered a fair quantum of instructions in each cycle.
                     * The quantum gives quantum (number) of instructions at a time, so it must remember how much has previously been
                     * offered to the process to ensure only the fair number of instructions are offered to each process in each cycle.
                     * If a process is blocked when it is it's turn to be scheduled, it is moved to the back of the
                     * queue and must wait for it to be called on the next cycle.
                     */
                    let offer = 0;
                    if (this.remaining >= this.quantum - this.quantumConsumed) {
                        offer = this.quantum - this.quantumConsumed;
                        this.quantumConsumed = 0;
                    } else {
                        offer = this.remaining;
                        this.quantumConsumed += this.remaining;
                        moveProcess = false;
                    }
                    this.remaining = this.remaining - offer + this.simulator.take(core.process, offer, this.total);
                }
                if (moveProcess) {
                    let pHead = processTable.shift(); // move process that has just been offered quantum to end of table
                    processTable.push(pHead);
                }

                /**
                 * if the process is done we remove from the process table and the core
                 * add to completed process table so we can view it
                 */
                this.simulator.completedProcessTable.push(...processTable.filter(item => item.isDone()));
                processTable = processTable.filter(item => !item.isDone());
                if (core.process && core.process.isDone()) {
                    core.process = null;
                }
            }

            this.updateRemaining(processTable);
        }
    }
}
