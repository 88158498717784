
let pidCounter = 0;

/**
 * state R = ready, W = waiting, N = running, X = ended
 */
export class Process {
    /**
     *
     * @param name Name given to process
     * @param cpuNeeded Total number of instructions required to be done, -1 if it is an unbounded process
     * @param arrivalTime Time (in instructions) the process arrives at the scheduler
     * @param priority used for priority scheduling only
     */
    constructor(name, pid=-1, cpuNeeded = -1, arrivalTime= 0, priority = 1) {
        // pid can either be passed in or can be automatically generated
        this.pid = pid === -1 ? ++pidCounter : pid;
        this.name = name;
        this.state = 'Ready'; // in ready state
        this.consumedInstructions = 0;
        this.cpuNeeded = cpuNeeded;
        this.arrivalTime = arrivalTime;
        this.finishedTime = null;
        this.priority = priority;
    }

    type() {
        return 'Process';
    }

    deepCopy() {
        console.error('must be implemented in derived classes');
        return null;
    }

    consume(ninstructions) {
        this.consumedInstructions += ninstructions;
    }

    // eslint-disable-next-line no-unused-vars
    readyOrNot(instructions) {
        console.error("Process.readyOrNot is abstract!");
        return true;
    }

    setRunning() {
        this.state = 'Running';
    }

    isDone() {
        return this.state === 'Done';
    }

    setDone() {
        this.state = 'Done';
    }

    isReady() {
        return this.state === 'Ready';
    }

    setReady() {
        this.state = 'Ready';
    }

    isRunning() {
        return this.state === 'Running';
    }

    isBlocked() {
        return this.state === 'Blocked';
    }

    setBlocked() {
        this.state = 'Blocked';
    }

    // eslint-disable-next-line no-unused-vars
    take(maxInstructions, totalInstructions) {
        console.log('Process::take is abstract');
    }

    /**
     * how much cpu does the process still need
     */
    cpuStillNeeded() {
        if (this.cpuNeeded === -1) {
            return -1;
        }
        return this.cpuNeeded - this.consumedInstructions;
    }

    /**
     * find time when process is completed
     */
    setFinishedTime(instructions) {
        this.finishedTime = instructions;
    }

    static resetPid(){
        pidCounter = 0;
    }


}
