import React from "react";
import {FaCheckCircle, FaRegHandPaper, FaRunning, FaThumbsUp} from "react-icons/fa";
import {Time} from "../simulator/Time";
import {Simulator} from "../simulator/Simulator";

export function AltProcessView({process}) {
    let state;
    let iconSize = 24;
    switch (process.state) {
        case 'Ready':
            state = <FaThumbsUp color="#FB9E00" size={iconSize}/>;
            break;
        case 'Running':
            state = <FaRunning color="green" size={iconSize}/>;
            break;
        case 'Blocked':
            state = <FaRegHandPaper color="red" size={iconSize}/>;
            break;
        default:
            state = <FaCheckCircle color="teal" size={iconSize}/>;
            break;
    }
    const formattedArrivalTime = new Time(process.arrivalTime, Simulator.instance.cpu.ips).format();
    const formattedFinishedTime = new Time(process.finishedTime, Simulator.instance.cpu.ips).format();


    return (
        <>
            <td align="center">{process.pid}</td>
            <td>{process.name}</td>
            <td>{process.type()}</td>
            <td>{formattedArrivalTime}</td>
            <td>{process.cpuNeeded}</td>
            <td>{}</td>
            <td align="center">{state}</td>
            <td>{formattedFinishedTime}</td>
        </>

    );
}