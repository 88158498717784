import React, {useEffect, useState} from "react";
import {Simulator} from "../simulator/Simulator";
import Button from "react-bootstrap/Button";
import {FaClock, FaFastBackward, FaPause, FaPlay, FaPlus, FaTrash} from "react-icons/fa";
import {ProcessTableView} from "../views/ProcessTableView";
import {ProcessTableFullView} from "../views/ProcessTableFullView";
import {ClockView} from "../views/ClockView";
import {Dropdown} from "react-bootstrap";
import {ConstantLoadProcess} from "../simulator/ConstantLoadProcess";
import {IOBoundProcess} from "../simulator/IOBoundProcess";
import {Process} from "../simulator/Process";

let processCounter = 1;
export function InteractiveSimulation() {
    const [ptable, setProcessTable] = useState(Simulator.instance.processTable);
    const [doneTable, setDoneTable] = useState(Simulator.instance.completedProcessTable);
    const [pauseState, setPauseState] = useState(false);
    const [timeState, setTimeState] = useState(Simulator.instance.clock.totalInstructions);

    useEffect(() => {
        setProcessTable(Simulator.instance.processTable);
        setDoneTable(Simulator.instance.completedProcessTable);
        Simulator.instance.resetClock();
        Simulator.instance.clock.addListener(setTimeState);
        return () => {
            Simulator.instance.reset();
            Simulator.instance.clock.removeListener(setTimeState);
        }
    }, []);

    function terminateSimulation() {
        Simulator.instance.reset();
        setDoneTable(Simulator.instance.completedProcessTable);
        setProcessTable(Simulator.instance.processTable);
        setTimeState(0);
        Process.resetPid();
        processCounter = 1;
    }

    function pauseClicked(e) {
        e.preventDefault();
        setPauseState(!pauseState);
        /**
         * the reactJS eventloop will set the pauseState value, but it has not set it yet!
         * we know it will be toggled, so this condition looks back to front, but is correct ...
         */
        if (!pauseState) {
            Simulator.instance.stop();
        } else {
            Simulator.instance.start(setProcessTable, setDoneTable);
        }
    }

    // for button to alter speed of simulation
    function setSpeed(e) {
        e.preventDefault();
        if (e.target.id === '0.125-speed') {
            Simulator.instance.clock.setSpeed(10);

        } else if (e.target.id === '0.25-speed') {
            Simulator.instance.clock.setSpeed(20);
        } else if (e.target.id === '0.5-speed') {
            Simulator.instance.clock.setSpeed(40);
        } else {
            Simulator.instance.clock.setSpeed(80);
        }
    }


    // for button to add constant load process
    function addConstantLoad(e){
        e.preventDefault();
        let arrivalTime = Simulator.instance.clock.totalInstructions;
        let constantLoad = new ConstantLoadProcess(`Random Process ${processCounter}` , -1, 600,
            arrivalTime);
        processCounter++;
        Simulator.instance.addProcess(constantLoad);
        setProcessTable([...Simulator.instance.processTable]);
    }


    // for button to add IO bound process
    function addIOBound(e){
        e.preventDefault();
        let arrivalTime = Simulator.instance.clock.totalInstructions;
        let ioBound = new IOBoundProcess(`Random Process ${processCounter}`, -1, 600, 200, arrivalTime);
        processCounter++;
        Simulator.instance.addProcess(ioBound);
        setProcessTable([...Simulator.instance.processTable]);
    }

    return (
        <>
            <div className="d-flex mx-4 mt-3">
                <h1 className="w-50 font-weight-light">{Simulator.instance.scheduler.name}</h1>
                <h1 className="w-50 font-weight-light">Simulator</h1>
            </div>
            <div className="d-flex mx-3">
                <div className="w-50">
                    <div className="d-flex mx-3">
                        <Button className="m-1" variant='info' disabled={Simulator.instance.processTable.length !== 0}
                                onClick={() => Simulator.instance.rewindToStart()}>
                            <FaFastBackward/></Button>
                        <Button className="m-1" variant='info' onClick={() =>
                            Simulator.instance.start(setProcessTable, setDoneTable)}><FaPlay/></Button>
                        <Button className="m-1" variant='info' onClick={pauseClicked} ><FaPause/></Button>
                       <Dropdown className="m-1" drop='down'>
                           <Dropdown.Toggle id='plus-dropdown' variant='info'>
                               <FaPlus/>
                           </Dropdown.Toggle>
                           <Dropdown.Menu>
                               <Dropdown.Item onClick={addConstantLoad} as="button" id="add-clp">Add Constant Load Process</Dropdown.Item>
                               <Dropdown.Item onClick={addIOBound} as="button" id="add-io">Add I/O Bound Process</Dropdown.Item>
                           </Dropdown.Menu>

                       </Dropdown>
                        <Button className="m-1" variant='danger' onClick={terminateSimulation} ><FaTrash/></Button>
                    </div>
                </div>
                <div className="d-flex w-50">
                    <ClockView className="my-2 mx-4 w-25 align-content-center text-monospace" time={timeState}/>
                    <Dropdown className="my-2 w-25" drop='right'>
                        <Dropdown.Toggle  id='speed-dropdown' variant='info'>
                            <FaClock/> &nbsp; {Simulator.instance.clock.instructionsPerTick/80}  &nbsp;
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={setSpeed} as="button" id="0.125-speed">0.125</Dropdown.Item>
                            <Dropdown.Item onClick={setSpeed} as="button" id="0.25-speed">0.25</Dropdown.Item>
                            <Dropdown.Item onClick={setSpeed} as="button" id="0.5-speed">0.5</Dropdown.Item>
                            <Dropdown.Item onClick={setSpeed} as="button" id="1-speed">1.0</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="d-flex mx-3">
                <ProcessTableFullView className="w-50 mx-3 shadow" ptable={ptable} doneTable={doneTable} priority={Simulator.instance.scheduler.id===5} />
                <ProcessTableView className="w-50 mx-3 shadow" ptable={ptable} format={2} priority={Simulator.instance.scheduler.id===5}/>
            </div>
        </>
    );
}