export class Scheduler {
    constructor(name, id) {
        this.name = name;
        this.id = id;
        this.remaining = 0;
        this.total = 0;
        this.lastRemaining = 0;
        this.instructions = 0;
    }

    setSimulator(sim) {
        this.simulator = sim;
    }

    // eslint-disable-next-line no-unused-vars
    schedule(total, instructions) {
        console.error('Scheduler::schedule is abstract and should not be called');
    }

    /**
     * we are changing process state from blocked to ready if desired
     * only the process knows
     */
    updateStatus(instructions) {
        for (let i = 0; i !== this.simulator.processTable.length; ++i) {
            let proc = this.simulator.processTable[i];
            if (proc.isDone()) {
                continue;
            }

            if (proc.readyOrNot(instructions)) {
                proc.setReady();
            } else {
                proc.setBlocked();
            }
        }
    }

    /**
     * Find the first ready process
     */
    findReadyProcess() {
        for (let i = 0; i !== this.simulator.processTable.length; ++i) {
            let proc = this.simulator.processTable[i];
            if (proc.isReady()) {
                return proc;
            }
        }
        return null;
    }

    updateRemaining(processTable) {
        if (this.remaining === this.lastRemaining) {
            /**
             * no process ready to process.
             * move forward in time and one may unblock
             */
            this.remaining -= Math.trunc(this.instructions / 10);
            if (this.remaining < 0) {
                this.remaining = 0;
            }
        }
        this.lastRemaining = this.remaining;
        this.simulator.updateProcesses(processTable);
    }

    updateProcesses(processTable) {
        /**
         * each core can have a running process
         */
        for (let icore = 0; icore !== this.simulator.cpu.cores.length; ++icore) {
            let core = this.simulator.cpu.cores[icore]

            core.process = this.findReadyProcess();

            /**
             * it is possible that no processes are able to run
             */
            if (core.process) {
                core.process.setRunning();
                this.remaining = this.simulator.stake(core.process, this.remaining, this.total);
            }

            /**
             * if the process is done we remove from the process table and the core
             * add to completed process table so we can view it
             */
            this.simulator.completedProcessTable.push(...processTable.filter(item => item.isDone()));
            processTable = processTable.filter(item => !item.isDone());
            if (core.process && core.process.isDone()) {
                core.process = null;
            }
        }

        if (this.remaining === this.lastRemaining) {
            /**
             * no process ready to process.
             * move forward in time and one may unblock
             */
            this.remaining -= Math.trunc(this.instructions / 10);
            if (this.remaining < 0) {
                this.remaining = 0;
            }
        }
        this.lastRemaining = this.remaining;
        this.simulator.processTable = processTable;
        this.simulator.updateProcesses(processTable);

        return processTable;
    }
}