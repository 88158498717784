import React, {useEffect, useState} from "react";
import DropdownButton from 'react-bootstrap/DropdownButton'
import {Dropdown} from "react-bootstrap";
import {Simulator} from "../simulator/Simulator";
import {FirstComeFirstServed} from "../simulator/FirstComeFirstServed";
import {RoundRobin} from "../simulator/RoundRobin";
import {ShortestJobFirst} from "../simulator/ShortestJobFirst";
import {ProcessTableView} from "../views/ProcessTableView";
import {ConstantLoadProcessView} from "../views/ConstantLoadProcessView";
import {ConstantLoadProcess} from "../simulator/ConstantLoadProcess";
import {IOBoundProcess} from "../simulator/IOBoundProcess";
import {IOBoundProcessView} from "../views/IOBoundProcessView";
import {ShortestRemainingTime} from "../simulator/ShortestRemainingTime";
import {PriorityScheduling} from "../simulator/PriorityScheduling";
import {Process} from "../simulator/Process";
import {Link} from "react-router-dom";


export function Settings() {
    const [selectedSchedulerTitle, setSelectedSchedulerTitle] = useState(Simulator.instance.processTable.length === 0 ?
        "Select an Algorithm" : getAlgorithmName(Simulator.instance.scheduler.id));
    const [nprocess, setnprocess] = useState(undefined);
    const [processTable, setProcessTable] = useState(Simulator.instance.processTable);
    const [selectedScheduler, setSelectedScheduler] = useState(Simulator.instance.processTable.length === 0 ?
        0 : Simulator.instance.scheduler.id); // no scheduler
    const [selectedTitleProcessType, setSelectedTitleProcessType] = useState('Select Process Type');


    useEffect(() => {
        Simulator.instance.processTable = processTable;
    }, [processTable]);


    function selectScheduler(event) {
        event.preventDefault();
        selectSchedulerOnID(event.target.id);
        Process.resetPid();
        if (processTable.length > 0){
            for (let i = 0; i < processTable.length ; i++){
                removeProcess(i);
            }
        }
    }

    // choose the algorithm
    function selectSchedulerOnID(id) {
        if (selectedScheduler === id) {
            return;
        }
        switch (id) {
            case 'fcfs-sched':
                Simulator.instance.setScheduler(new FirstComeFirstServed());
                setSelectedScheduler(1);
                break;

            case 'sjf-sched':
                Simulator.instance.setScheduler(new ShortestJobFirst());
                setSelectedScheduler(2);
                break;

            case 'srt-sched':
                Simulator.instance.setScheduler(new ShortestRemainingTime());
                setSelectedScheduler(3);
                break;

            case 'rr-sched':
                Simulator.instance.setScheduler(new RoundRobin());
                setSelectedScheduler(4);
                break;

            case 'priority-sched':
                Simulator.instance.setScheduler(new PriorityScheduling());
                setSelectedScheduler(5);
                break;

            default:
                console.log('unexpected scheduler:', id);
        }
        setSelectedSchedulerTitle(Simulator.instance.scheduler.name);
    }

    function getAlgorithmName(id) {
        switch (id) {
            case 1 :
                return 'First Come First Served';
            case 2 :
                return 'Shortest Job First';
            case 3 :
                return 'Shortest Remaining Time';
            case 4 :
                return 'Round Robin';
            case 5 :
                return 'Priority Scheduling';
            default:
                return 'unknown algorithm';
        }
    }

    function selectCLProcess(event) {
        event.preventDefault();
        setnprocess(new ConstantLoadProcess());
        setSelectedTitleProcessType('Constant Load Process');
    }

    function selectIOProcess(event) {
        event.preventDefault();
        setnprocess(new IOBoundProcess());
        setSelectedTitleProcessType('I/O Bound Process');
    }

    function addProcess(proc) {
        if (proc) {
            setProcessTable([...processTable, proc]);
        }
        setnprocess(undefined);
        setSelectedTitleProcessType('Select Process Type');
    }

    function removeProcess(proc) {
        let ntable = processTable.filter(item => item.pid !== proc.pid);
        setProcessTable(ntable);

    }

    let editor = '';
    if (nprocess) {
        if (nprocess.type() === 'Constant Load Process') {
            editor = <ConstantLoadProcessView scheduler={selectedScheduler} process={nprocess} change={addProcess}
                                              edit={true}/>;
        } else {
            editor =
                <IOBoundProcessView scheduler={selectedScheduler} process={nprocess} change={addProcess} edit={true}/>;
        }
    }

    return (
        <>
            <div className="mx-4 mt-3 d-flex flex-column">
                <h1 className="font-weight-light" align="center">Set Up</h1>
                <h4 className="font-weight-light" align="center">Choose the algorithm you wish to simulate and add
                    processes to the process table.</h4>
                <div className='d-flex m-3'>
                    <div className="w-50 d-flex flex-column mx-1">
                        <div className="d-flex justify-content-around">
                            <DropdownButton variant='info' id="dropdown-scheduler" title={selectedSchedulerTitle}>
                                <Dropdown.Item as="button" id="fcfs-sched"
                                               onClick={selectScheduler}>{getAlgorithmName(1)}</Dropdown.Item>
                                <Dropdown.Item as="button" id="sjf-sched"
                                               onClick={selectScheduler}>{getAlgorithmName(2)}</Dropdown.Item>
                                <Dropdown.Item as="button" id="srt-sched"
                                               onClick={selectScheduler}>{getAlgorithmName(3)}</Dropdown.Item>
                                <Dropdown.Item as="button" id="rr-sched"
                                               onClick={selectScheduler}>{getAlgorithmName(4)}</Dropdown.Item>
                                <Dropdown.Item as="button" id="priority-sched"
                                               onClick={selectScheduler}>{getAlgorithmName(5)}</Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton variant='info' id="dropdown-process" disabled={selectedScheduler === 0}
                                            title={selectedTitleProcessType}>
                                <Dropdown.Item as="button" id="cl-proc" data-bs-toggle="tooltip" data-bs-placement="right"
                                               title="Tooltip on right" onClick={selectCLProcess}>Constant Load
                                    Process</Dropdown.Item>
                                <Dropdown.Item as="button" id="io-proc" onClick={selectIOProcess}>I/O Bound
                                    Process</Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div>{editor}</div>
                    </div>
                    <div className="w-50 mx-1">
                        <h3 className="font-weight-normal" align="center">{selectedSchedulerTitle}</h3>
                        <ProcessTableView ptable={processTable} change={removeProcess} editable={true}
                                          priority={selectedScheduler === 5}
                                          format={1}/>

                        <div align="center">
                            <Link to="/simulation" className="btn btn-info" >Simulate</Link>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}