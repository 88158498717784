import React from "react";
import Button from "react-bootstrap/Button";
import {FaTrash, FaRunning, FaThumbsUp, FaRegHandPaper, FaCheckCircle} from "react-icons/fa";


export function ProcessView({process, change= undefined, editable= false, priority= false,
                            format= 1}) {
    let state;
    const iconSize = 24;
    switch (process.state) {
        case 'Ready':
            state = <FaThumbsUp color="#FB9E00" size={iconSize}/>;
            break;
        case 'Running':
            state = <FaRunning color="green" size={iconSize}/>;
            break;
        case 'Blocked':
            state = <FaRegHandPaper color="red" size={iconSize}/>;
            break;
        default:
            state = <FaCheckCircle color="teal" size={iconSize}/>;
            break;
    }

    let view = '';
    if (format === 1) {
        view = (
            <>

                <td align="center">{process.pid}</td>
                <td>{process.name}</td>
                <td>{process.type()}</td>
                <td>{process.cpuNeeded}</td>
                {priority && <td align="center">{process.priority}</td>}
                { editable && <td align="center"><Button variant="danger" onClick={() => change(process)}><FaTrash/>
                </Button></td> }
            </>
        );
    } else if (format === 2) {
        view = (
            <>
                <td align="center"><div>{state}</div> <div>{process.consumedInstructions}</div></td>
                <td align="center">{process.pid}</td>
                <td>{process.name}</td>
                <td>{process.type()}</td>
                <td>{process.cpuNeeded}</td>
                {priority && <td align="center">{process.priority}</td>}
            </>

            );
    }

    return view;
}