import React, {useState} from "react";
import brand from './schimulator.png';
import './App.css';
import {Link, Route, Switch} from "react-router-dom";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import {About} from "./pages/About";
import {InteractiveSimulation} from "./pages/InteractiveSimulation";
import {Future} from "./pages/Future";
import {Settings} from "./pages/Settings";


function App() {
    const [loading, setLoading] = useState(true);

    if (loading) {
        setTimeout(() => setLoading(false), 3000);

        return (
            <div className="splash d-flex align-items-center justify-content-center vh-100">
                <img className="shadow-lg" src={brand} alt="splash screen"/>
            </div>
        );
    }

    return (
        <>
            <Navbar bg="light" sticky="top">
                <Navbar.Brand as={Link} to="/about">
                    <img src={brand} width='150px' className="d-inline-block align-top" alt="PSA"/>
                </Navbar.Brand>
                <Navbar.Collapse>
                    <Nav className="mr-auto">

                        <NavItem eventkey={3} href="/settings">
                            <Nav.Link as={Link} to="/settings">Set Up</Nav.Link>
                        </NavItem>
                        <NavItem eventkey={4} href="/simulation">
                            <Nav.Link as={Link} to="/simulation">Simulate Algorithm</Nav.Link>
                        </NavItem>
                        <NavItem eventkey={5} href="/future">
                            <Nav.Link as={Link} to="/future">Download Simulation</Nav.Link>
                        </NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Switch>
                <Route exact path='/about' component={About}/>
                <Route exact path='/settings' component={Settings}/>
                <Route exact path='/simulation' component={InteractiveSimulation}/>
                <Route exact path='/future' component={Future}/>
                <Route path='/' component={About}/>
            </Switch>
        </>

    );
}

export default App;
