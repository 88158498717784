import React, {useState} from "react";
import {Simulator} from "../simulator/Simulator";
import Button from "react-bootstrap/Button";


export function Future() {
    // eslint-disable-next-line no-unused-vars
    const [history, setHistory] = useState(Simulator.instance.history);

    function downloadFile() {
        const element = document.createElement("a");
        let data = "PID, Name, State, Num Instructions, Time\n";
        let counter = 0;
        for (let i=0; i !== history.length; ++i) {
            let item = history[i];
            counter += item.instructions;
            data += `${item.pid},${item.name},${item.state},${item.instructions},${counter}\n`
        }
        const file = new Blob([data], {
            type: "application/csv"
        });
        element.href = URL.createObjectURL(file);
        element.download = "cpu-history.csv";
        document.body.appendChild(element);
        element.click();
    }

    return (

        <>
            <div className="d-flex mx-5 mt-4 mb-0 flex-column align-items-center">
                <Button className="m-1 w-25" variant='info' onClick={downloadFile}>
                    <h5 className="font-weight-light">Download history as csv</h5>
                </Button>
            </div>


            <div className="container text-center my-4" >
            <h2 className="font-weight-light">Planned Extension to Project</h2>
            </div>


            <div className="container alert alert-info text-center col-6" role="alert">
                <h4 className="font-weight-light">Processes running at the top of the simulator table.</h4>
            </div>
            <div className="container alert alert-info text-center col-6" role="alert">
                <h4 className="font-weight-light">Gantt chart view of processes.</h4>
            </div>
            <div className="container alert alert-info text-center col-6" role="alert">
                <h4 className="font-weight-light">CSV shows the state of the process when it has been switched out.</h4>
            </div>
            <div className="container alert alert-info text-center col-6" role="alert">
                <h4 className="font-weight-light">Continual fixing of bugs as they are found.</h4>
            </div>
            <div className="container alert alert-info text-center col-6" role="alert">
                <h4 className="font-weight-light">Multi-core functionality and visualisations.</h4>
            </div>
            <div className="container alert alert-info text-center col-6" role="alert">
                <h4 className="font-weight-light">Visualisations and demonstrations of inter-process synchronisation.</h4>
            </div>

        </>

    );

}