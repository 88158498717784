import {Process} from "./Process";

export class IOBoundProcess extends Process {
    constructor(name=undefined, pid = -1, instructions = 1000, busyPeriod = 200, arrivalTime= 0,
                priority = 1) {
        super(name, pid, instructions, arrivalTime, priority);
        this.instructions = instructions;
        this.blockedSince = 0;
        this.sofar = 0;
        this.busyPeriod = busyPeriod;
    }

    type() {
        return 'I/O Bound Process';
    }

    /**
     * determine whether a process is ready to run ot not
     */
    readyOrNot(instructions) {
        // is it ready and can it be run
        if (this.sofar < this.busyPeriod) {
            return this.isReady() || this.isRunning();
        }
        // count number of instructions process has been blocked for
        if (this.blockedSince === 0) {
            this.blockedSince = instructions;
        // check to see if blocked period is greater than the time that the process should be blocked for
        // if it is greater than that period set it to ready, otherwise block
        } else if (instructions - this.blockedSince > this.busyPeriod) {
            this.sofar = 0;
            this.blockedSince = 0;
            return true;
        }

        return false;
    }

    take(maxInstructions, totalInstructions) {
        /**
         * need to determine how many instructions we take before we are blocked
         * we keep a running total of how many instructions processed since last blocked
         */
        let iomax = this.busyPeriod - this.sofar;
        let n = iomax < maxInstructions ? iomax : maxInstructions;
        if (this.instructions > n) {
            this.instructions -= n;
            this.consume(n);
            this.sofar += n;
            return maxInstructions - n;
        }

        const remaining = maxInstructions - n;
        this.consume(n);
        this.finishedTime = totalInstructions + n;
        this.instructions = 0;
        this.setDone();
        return remaining;
    }

    deepCopy() {
        return new IOBoundProcess(this.name, this.pid, this.instructions, this.busyPeriod, this.arrivalTime,
            this.priority);
    }

}