import {Scheduler} from "./Scheduler";

export class FirstComeFirstServed extends Scheduler {
    constructor() {
        super("First Come First Served", 1);

        this.method = 'ordered';
    }

    /**
     * aid for debugging
     */
    printTable(msg, pt) {
        console.log(msg);
        for (let i=0; i !== pt.length; ++i) {
            let proc = pt[i];
            console.log(proc.name, proc.state);
        }
    }

    readyAtTop(){
        let proc = this.simulator.processTable[0];
        let pid = proc.pid;
        while (!proc.isReady()) {
            proc = this.simulator.processTable.shift(); // shifts out first element of the array
            this.simulator.processTable.push(proc); // process at end of table
            proc = this.simulator.processTable[0];
            if (proc.pid === pid) {     // prevent forever loop if no process is ready
                return null;
            }
        }
        return proc;
    }

    schedule(total, instructions) {
        this.instructions = instructions;
        this.total = total;
        this.remaining = instructions;
        let processTable = this.simulator.processTable;
        this.lastRemaining = this.remaining;
        while (this.remaining && processTable.length !== 0) {
            //update each process ready/blocked at each interval
            this.updateStatus(this.total + this.instructions - this.remaining);
            // each core can have a running process
            for (let icore = 0; icore !== this.simulator.cpu.cores.length; ++icore) {
                let core = this.simulator.cpu.cores[icore]

                core.process = this.readyAtTop();

                /**
                 * it is possible that no processes are able to run
                 */
                if (core.process) {
                    core.process.setRunning();
                    this.remaining = this.simulator.take(core.process, this.remaining, this.total + this.instructions - this.remaining);
                }

                /**
                 * if the process is done we remove from the process table and the core
                 * add to completed process table so we can view it
                 */
                this.simulator.completedProcessTable.push(...processTable.filter(item => item.isDone()));
                processTable = processTable.filter(item => !item.isDone());
                if (core.process && core.process.isDone()) {
                    core.process = null;
                }
            }
            this.updateRemaining(processTable);
        }
    }
}
