import {Clock} from "./Clock";
import {FirstComeFirstServed} from "./FirstComeFirstServed";
import {Cpu} from './Cpu';
import {ProcessHistory} from "./ProcessHistory";

export class Simulator {
    constructor(scheduler, cpu) {
        this.processTable = [];
        this.completedProcessTable = [];
        this.scheduler = scheduler;
        this.clock = new Clock(this);
        this.cpu = cpu;
        this.history = [];
        scheduler.setSimulator(this);
    }

    addProcess(p) {
        this.processTable.push(p);
    }

    // used for the start button on simulateAlgorithms page
    start(setProcessTable, setDoneTable) {
        let deepCopyTable = [];
        for (let i = 0 ; i !== this.processTable.length ; ++i) {
            deepCopyTable.push(this.processTable[i].deepCopy());
        }
        this.initialPtable = deepCopyTable;
        this.setProcessTable = setProcessTable;     // fill process table
        this.setDoneTable = setDoneTable;           // fill done table
        this.clock.start();     // start clock
    }

    // used for pause button on simulateAlgorithms page
    stop() {
        this.clock.stop();
    }

    // used to reset clock when restarting a simulation
    resetClock() {
        this.stop();
        this.clock.reset();
    }

    // used to reset and clear the clock and ptables when trashing a simulation (button)
    reset() {
        this.resetClock();
        this.processTable = [];
        this.completedProcessTable = [];
        this.history = [];
    }

    // updating process in tables as they use instructions
    updateProcesses(processTable) {
        this.processTable = processTable;
        this.setProcessTable([...this.processTable]); // copies process table. required for reactJS
        this.setDoneTable([...this.completedProcessTable]);
    }

    // gets called by the clock to update the scheduler
    tick(total, instructions) {
        this.scheduler.schedule(total, instructions);
    }

    // set the algorithm that has been selected for this simulation
    setScheduler(s) {
        this.scheduler = s;
        s.setSimulator(this);
    }

    // rewind clock and use initial ptable so user can replay the simulation
    rewindToStart() {
        this.completedProcessTable = [];
        this.history = [];
        this.resetClock();
        this.updateProcesses([...this.initialPtable]);
    }

    store(process, instructions) {
        let item = this.history.pop();
        if (item && item.pid === process.pid) {
            item.instructions += instructions;
        } else {
            if (item) {
                this.history.push(item);
            }
            item = new ProcessHistory(process, instructions);
        }
        this.history.push(item);
        console.log("history: ", this.history);
    }

    take(process, instructions, sofar) {
        let remaining = process.take(instructions, sofar);
        this.store(process, instructions - remaining);
        return remaining;
    }

    // have a singleton
    static instance = new Simulator(new FirstComeFirstServed(), new Cpu(1, 1e5));
}
