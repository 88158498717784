import {Process} from "./Process";

export class ConstantLoadProcess extends Process {
    constructor(name= undefined, pid=-1, instructions = 1000, arrivalTime= 0, priority= 1) {
        super(name, pid, instructions, arrivalTime, priority);
        this.instructions = instructions;
    }

    type() {
        return 'Constant Load Process';
    }

    // eslint-disable-next-line no-unused-vars
    readyOrNot(instructions) {
        return this.isReady() || this.isRunning();
    }

    /** how a constant load process gets instructions from the CPU, decrementing the amount of instructions a
     * process still needs
     */
    take(maxInstructions, totalInstructions) {
        /**
         * If number of instructions needed is greater than the number of instructions that the cpu has left, then take
         * all of what is left.
         */
        if (this.instructions > maxInstructions) {
            this.instructions -= maxInstructions;
            this.consume(maxInstructions);
            return 0;
        }

        /**
         * Process takes as many instructions as it needs before it is done or blocked
         * Calculate finished time
         */
        const remaining = maxInstructions - this.instructions;
        this.consume(this.instructions);
        this.finishedTime = totalInstructions + this.instructions;
        this.instructions = 0;
        this.setDone();
        return remaining;
    }

    deepCopy() {
        return new ConstantLoadProcess(this.name, this.pid, this.instructions, this.arrivalTime, this.priority);
    }
}
