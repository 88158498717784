export class Time {
    constructor(instructions, ips) {
        this.instructions = instructions;
        this.ips = ips;
    }

    /**
     * Time displayed is a simulated time of how long these processed would take to be completed inside a real machine.
     * This is worked out from the instructions and the number of instructions that can be processed by the cpu per
     * second.
     */

    time() {
        const seconds = this.instructions / this.ips;
        const hours = Math.trunc(seconds / 360);
        const minutes = Math.trunc( (seconds - (hours * 360)) / 60);
        const secs = Math.trunc(seconds - (hours * 360 + minutes * 60));
        const nano = (seconds - parseInt(seconds)) * 1e9;

        return { hours: hours, minutes: minutes, seconds: secs, nano: nano};
    }


    integerTime() {
        return this.instructions;
    }

    format() {
        // return (Math.round(this.instructions*1e6/this.ips)/1e6).toFixed(6); // for real time
        return this.instructions;
    }
}