import React from "react";
import {AltProcessView} from "./AltProcessView";
import {Table} from "react-bootstrap";

export function ProcessTableFullView({ptable, doneTable, className}) {
    return (
            <Table className={className} bordered>
                <thead>
                <tr>
                    <th>PID</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Arrival Time</th>
                    <th>Total Instructions</th>
                    <th>Times Blocked</th>
                    <th>State</th>
                    <th>Finished Time</th>
                </tr>
                </thead>
                <tbody>
                    {ptable.map(proc => <tr key={"x" + proc.pid.toString()}><AltProcessView process={proc} /></tr>)}
                    {doneTable.map(proc => <tr key={"d" + proc.pid.toString()}><AltProcessView process={proc}/></tr>)}
                </tbody>
            </Table>
    );

}