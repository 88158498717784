import React from "react";
import {Time} from "../simulator/Time";
import {Simulator} from "../simulator/Simulator";

export function ClockView({time, className}) {

    let clockTime = new Time(time, Simulator.instance.cpu.ips).format();

    return (
        <h2 className={className}>{clockTime}</h2>
        );

}