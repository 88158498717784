import React from "react";
import {ProcessView} from "./ProcessView";
import {Table} from "react-bootstrap";


export function ProcessTableView({
                                     ptable, change = undefined, editable = false,
                                     priority = false, format = 1, className
                                 }) {

    return (
        <Table className={className} bordered>
            <thead>
            <tr>
                {format === 2 && <th>State</th>}
                <th className='col-1'>PID</th>
                <th className='col-3'>Name</th>
                <th className='col-3'>Type</th>
                <th className='col-3'>Total Instructions</th>
                {priority && <th className='col-1'> Priority</th>}
            </tr>
            </thead>
            <tbody>
            {ptable.map(proc => <tr key={proc.pid}><ProcessView
                process={proc} change={change} editable={editable} priority={priority} format={format}/></tr>)}
            </tbody>
        </Table>
    );

}