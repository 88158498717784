/**
 * Clock is used to simulate time
 * time is measured in instructions
 * wall clock time can be determined (roughly) using the processors instructions per second (mips)
 * we cannot keep up with a real processor, so we set a number of instructions deemed to pass
 * per javascript timer interval
 */
export class Clock {
    constructor(simulator) {
        this.sim = simulator;
        this.instructionsPerTick = 80;
        this.reset();
        this.tickNotifiers = []; // functions to call when there is a clock update
    }

    addListener(fn) {
        this.tickNotifiers.push(fn);
    }

    removeListener(fn) {
        this.tickNotifiers = this.tickNotifiers.filter(item => item !== fn);
    }
    /**
     * setup a timer to go off periodically.
     * the smallest period is 1ms
     * an interval must be chosen that lets us see animation, but does not consume so much
     * cpu that the app becomes unresponsive
     */
    start() {
        /**
         * if timer is already running, do nothing
         */
        if (this.timerId) {
            return;
        }
        /**
         * Every 500ms do this work
         */
        this.timerId = setInterval(() => {
            this.sim.tick(this.totalInstructions, this.instructionsPerTick);
            for (let i=0; i !== this.tickNotifiers.length; ++i) {
                this.tickNotifiers[i](this.totalInstructions); // notify listeners to the clock
            }
            this.totalInstructions += this.instructionsPerTick;
            }, 500);
    }

    setSpeed(ipt) {
        let isRunning = this.timerId != null;
        this.stop();
        this.instructionsPerTick = ipt;
        if (isRunning) {
            this.start();
        }
    }

    stop() {
        if (this.timerId != null) {
            clearTimeout(this.timerId);
        }
        this.timerId = null;
    }


    reset() {
        this.stop();
        this.totalInstructions = 0;
    }
}